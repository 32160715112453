<template>
  <v-bottom-sheet v-model="dialog" inset scrollable>
    <v-card>
      <v-card-text style="height: 80vh" class="px-0">
        <v-btn @click="closeDialog" color="pink" dark absolute top right fab>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <business-contacts></business-contacts>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import dialogMixin from "@/mixins/dialogMixin"
import BusinessContacts from "@/components/BusinessContacts"

export default {
  created() {
    this.showDialog();
  },
  components: {
    BusinessContacts
  },
  mixins: [dialogMixin],
};
</script>
