<template>
  <v-card>
    <v-img class="mb-4" :src="business.cover_pic_link"></v-img>
    <div class="pa-5">
      <div class="d-flex flex-wrap align-end">
        <v-avatar
          class="profile-logo mr-4"
          color="grey"
          tile
          :style="business.cover_pic_link ? 'margin-top: -80px;' : ''"
        >
          <v-img :src="business.logo_pic_link"></v-img>
        </v-avatar>
        <div class="d-flex">
          <div class="flex-columns">
          <div class="text-h4 my-1 business-name">{{ business.name }}</div>
          <div class="text-h6">{{ business.biztype }} &bull; {{ business.bizdesc_short }}</div>
          </div>
        </div>
      </div>

      <v-row justify="center">
        <v-col cols="12" sm="8">
          <v-card class="mt-5">
            <v-card-title>Contact Information</v-card-title>
            <!-- <v-card-text>
                        <v-icon>mdi-phone</v-icon> {{ business.phone }}
                        <v-btn color="green" dark
                          ><v-icon>mdi-whatsapp</v-icon> WhatsApp</v-btn
                        >
                        <v-btn color="primary" dark
                          ><v-icon>mdi-phone</v-icon> Call Now</v-btn
                        >
                      </v-card-text> -->

            <v-list two-line>
              <v-list-item :href="'tel:' + business.phone">
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-phone</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ business.phone }}</v-list-item-title>
                  <v-list-item-subtitle>Call Now</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :href="'https://wa.me/' + business.phone"
                target="_blank"
              >
                <v-list-item-icon>
                  <v-icon color="green">mdi-whatsapp</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ business.phone }}</v-list-item-title>
                  <v-list-item-subtitle>WhatsApp</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item :href="getMapLink()" target="_blank">
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{
                    fullAddress()
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><v-icon small>mdi-information-outline</v-icon> Click to
                    view map</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <div class="py-3"></div>
    </div>
  </v-card>
</template>
<script>
export default {
  methods: {
    fullAddress() {
      let label = ["address1", "address2", "postcode", "city", "state"],
        address = [];
      label.forEach((a) => {
        if (this.business[a]) address.push(this.business[a]);
      });
      return address.join(" ");
    },
    getMapLink() {
      let latlon = this.business.lat + "," + this.business.lng;
      if (latlon != "0,0")
        return (
          "https://www.google.com/maps?q=" +
          latlon +
          "+(" +
          encodeURIComponent(this.business.name) +
          ")&z=15"
        );
      else
        return (
          "https://www.google.com/maps?q=" +
          encodeURIComponent(this.business.name + " " + this.fullAddress())
        );
    },
  },
  computed: {
    business() {
      return this.$store.state.business;
    },
  },
};
</script>
<style lang="sass" scoped>
  @import '~vuetify/src/styles/styles.sass'

  .profile-logo
    width: 160px !important
    height: 160px !important

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .profile-logo
      width: 100px !important
      height: 100px !important
    .business-name
      font-size: 2em !important
</style>